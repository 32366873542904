/* eslint-disable jsx-a11y/alt-text */
import { Slider } from '@mui/material';
import DT from 'duration-time-conversion';
import { useEffect, useState } from 'react';
import { Minus, Pause, Play, Plus } from 'react-feather';
import styled from 'styled-components';
import { useActions, useAppState, useReaction } from '../../overmind';
import { getDuration } from '../../utils';
import BackwardIcon from '../Icon/BackwardIcon.js';
import ForwardIcon from '../Icon/ForwardIcon.js';
import NextIcon from '../Icon/NextIcon.js';
import PreviousIcon from '../Icon/PreviousIcon.js';
import PlaybackSpeed from '../PlaybackSpeed.js';
import Volume from '../Volume';
import useComponentVisible from '../useComponentVisible';
const Style = styled.div`
    width: 100%;
    .player-control-container {
        position: relative;
        .player-control {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            position: relative;
            @media (max-width: 768px) {
                flex-wrap: wrap;
                justify-content: center;
                padding: 10px 0;
                gap: 0.25rem;
            }
        }
        .fullscreen {
            width: 100vw;
        }
        .time {
            color: #ffffff;
        }
        .time-total {
            color: rgba(255, 255, 255, 0.3);
            margin-left: 0.25rem;
        }
    }

    .skip-video-btn .action-dropdown-content {
        right: 10px;
        margin-top: 0;
    }
    .skip-video-btn .action-dropdown-content.custom-dropdown-menu.show > .custom-dropdown-menu__content {
        width: fit-content;
        margin-left: auto;
        min-width: unset;
        margin-top: 0.5rem;
    }
    .skip-video-btn .btn {
        background-color: #2b2c39;
        border-color: #2b2c39;
        color: #fff;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .skip-video-btn .btn:hover {
        border-color: #0c1352;
        color: #fff;
        background-color: #0c1352;
    }
    .skip-video-btn .action-dropdown-content.custom-dropdown-menu .custom-dropdown-menu__content a {
        background-color: #2b2c39;
        color: #fff;
    }
    .skip-video-btn .action-dropdown-content.custom-dropdown-menu .custom-dropdown-menu__content a:hover {
        background-color: #24252e;
    }
`;

const CurrentTime = () => {
    const state = useAppState();
    return (
        <p
            style={{
                width: '70px',
                textAlign: 'center',
            }}
            id="currentTime"
        >
            {getDuration(state.playerTime)}
        </p>
    );
};

export default function AudioPlayerControl(props) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [percentage, setPercentage] = useState(0);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [saving, setSaving] = useState(false); //untuk progressbar
    const state = useAppState();
    const actions = useActions();
    const [zoom, setZoom] = useState(state.waveformZoom || 200);
    const reaction = useReaction();
    useEffect(() =>
        reaction(
            ({ currentTime }) => currentTime,
            (currentTime) => {
                if (!saving) {
                    setPercentage(currentTime);
                }
            },
        ),
    );

    const handleSliderChange = (event, newValue) => {
        props.player.currentTime = newValue;
        setSaving(false);
    };

    const sliderChange = (event, newValue) => {
        setSaving(true);
        setPercentage(newValue);
    };

    const handleZoomSlider = (event, newValue) => {
        let value = (newValue / 100) * state.maxZoom;
        if (value < state.minZoom) {
            value = state.minZoom;
        }
        actions.setWaveformZoom(value);
    };

    const zoomSliderChange = (event, newValue) => {
        const value = (newValue / 100) * state.maxZoom;
        setZoom(value);
    };

    const getDuration = (time) => {
        time = time === Infinity || isNaN(time) ? 0 : time;
        return DT.d2t(time).split('.')[0];
    };

    useEffect(() => {
        if (playbackRate && props.player) {
            props.player.playbackRate = playbackRate;
            if (state.originalVocal) {
                state.originalVocal.playbackRate = playbackRate;
            }
            if (state.originalMusic) {
                state.originalMusic.playbackRate = playbackRate;
            }
            if (state.voiceOverAudio) {
                state.voiceOverAudio.playbackRate = playbackRate;
            }
        }
    }, [playbackRate, props.player, state.originalMusic, state.originalVocal, state.voiceOverAudio]);

    const onConfigClick = (value) => {
        setPlaybackRate(value);
    };
    return (
        <Style>
            <div
                className={`player-control-container bg-control-dark rounded-3px ${
                    props.fullscreen ? 'fullscreen' : ''
                }`}
            >
                <div className={`player-control ${props.fullscreen ? 'fullscreen' : ''}`}>
                    <div className="ml-3 flex items-center lg:order-1 order-2">
                        <button className="btn box-shadow-none border-none text-white btn-bg-control">
                            <PlaybackSpeed
                                name="playbackRate"
                                playbackRate={playbackRate}
                                onConfigClick={onConfigClick}
                            />
                        </button>
                        <div className="hide-fullscreen">
                            <Volume {...props} />
                        </div>
                    </div>

                    <div className="flex items-center order-1">
                        <button className="btn box-shadow-none border-none" onClick={props.reset}>
                            <PreviousIcon className="w-6 h-6 text-gray-500" />
                        </button>
                        <button className="btn box-shadow-none border-none" onClick={props.backward}>
                            <BackwardIcon className="w-6 h-6 text-white" />
                        </button>
                        <button className="btn box-shadow-none border-none text-title" onClick={props.playPauseAction}>
                            {props.playing ? (
                                <Pause
                                    className="w-8 h-8 fill-lexigo-white stroke-lexigo-white"
                                    onClick={props.playPauseAction}
                                />
                            ) : (
                                <Play
                                    className="w-8 h-8 fill-lexigo-white stroke-lexigo-white"
                                    onClick={props.playPauseAction}
                                />
                            )}
                        </button>

                        <span className="time text-content">
                            <CurrentTime />
                        </span>
                        <span className="time time-total flex items-center text-content">
                            <span>/</span>
                            <p
                                style={{
                                    width: '70px',
                                    textAlign: 'center',
                                }}
                            >
                                {props.player && getDuration(props.player.duration)}
                            </p>
                        </span>
                        <button className="btn box-shadow-none border-none" onClick={props.fastForward}>
                            <ForwardIcon className="w-6 h-6 text-white" />
                        </button>
                        <button className="btn box-shadow-none border-none" onClick={props.reset}>
                            <NextIcon className="w-6 h-6 text-gray-500" />
                        </button>
                    </div>

                    <div
                        className="mr-3 flex items-center gap-5 lg:order-1 order-2"
                        style={{ width: 'auto', textAlign: 'right' }}
                    >
                        <div className="hide-not-fullscreen">
                            <Volume {...props} />
                        </div>
                        <div className="flex items-center gap-2 text-white hide-for-mobile hide-fullscreen">
                            <Minus
                                className="w-5 h-5"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (zoom - 20 < state.minZoom) {
                                        setZoom(state.minZoom);
                                        actions.setWaveformZoom(state.minZoom);
                                    } else {
                                        setZoom(zoom - 20);
                                        actions.setWaveformZoom(zoom - 20);
                                    }
                                }}
                            />
                            <Slider
                                aria-label=""
                                value={((zoom - state.minZoom) / (state.maxZoom - state.minZoom)) * 100}
                                style={{ width: '80px' }}
                                color="light"
                                onChange={zoomSliderChange}
                                onChangeCommitted={handleZoomSlider}
                                size="small"
                            />
                            <Plus
                                style={{ cursor: 'pointer' }}
                                className="w-5 h-5"
                                onClick={() => {
                                    if (zoom + 20 > state.maxZoom) {
                                        setZoom(state.maxZoom);
                                        actions.setWaveformZoom(state.maxZoom);
                                    } else {
                                        setZoom(zoom + 20);
                                        actions.setWaveformZoom(zoom + 20);
                                    }
                                }}
                            />
                        </div>

                        <span
                            style={{ cursor: 'pointer', color: 'white' }}
                            className="text-gray-500 hide-for-mobile hide-fullscreen text-content"
                            onClick={() => {
                                setZoom(state.minZoom);
                                actions.setWaveformZoom(state.minZoom);
                            }}
                        >
                            Fit Timeline
                        </span>
                        {(props.access &&
                            props.access.accessMapping.role.toLowerCase() !== 'owner' &&
                            props.access.accessMapping.role.toLowerCase() !== 'editor') ||
                        (props.isShared == 1 &&
                            props.translateState == false &&
                            props.isOriginalLocked.length > 0 &&
                            props.isOriginalLocked[0] === 0) ? (
                            ''
                        ) : (
                            <div className="action-dropdown custom-dropdown skip-video-btn" ref={ref}>
                                <button
                                    className="dropdown-toggle btn bg-white font-bold"
                                    aria-expanded="true"
                                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <circle cx="6" cy="6" r="3"></circle>
                                        <circle cx="6" cy="18" r="3"></circle>
                                        <line x1="20" y1="4" x2="8.12" y2="15.88"></line>
                                        <line x1="14.47" y1="14.48" x2="20" y2="20"></line>
                                        <line x1="8.12" y1="8.12" x2="12" y2="12"></line>
                                    </svg>
                                </button>
                                <div
                                    className={`action-dropdown-content custom-dropdown-menu  ${
                                        isComponentVisible ? 'show' : ''
                                    }`}
                                >
                                    <div className="custom-dropdown-menu__content box">
                                        <a
                                            className="cursor"
                                            onClick={() => {
                                                actions.setTriggerTrimVideo(true);
                                            }}
                                        >
                                            Skip video
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Style>
    );
}
