/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Pause, Play, X } from 'react-feather';
import { Oval } from 'react-loading-icons';
import WaveformData from 'waveform-data';
import { useActions, useAppState } from '../../overmind';
import { fetchTranslationHeader } from '../../requests/TranslateRequest';
import {
    createVoiceOver,
    fetchVoiceOverLanguage,
    getVoiceOver,
    getVoiceOverPreview,
} from '../../requests/VoiceOverRequest';
import toast from 'react-hot-toast';

const VoiceOverModal = ({
    voiceOverModal,
    setVoiceOverModal,
    translateState,
    languageText,
    projectData,
    user,
    id,
    triggerLoadVoiceOver,
    player,
    currentLanguage,
    setPlaying,
}) => {
    const state = useAppState();
    const actions = useActions();
    const [languageList, setLanguageList] = useState([]);
    const [languageId, setLanguageId] = useState(-1);
    const [supported, setSupported] = useState(true);
    const [tmpLanguageId, setTmpLanguageId] = useState(null);
    const [tmpAudio, setTmpAudio] = useState(null);
    const [tmpAudioState, setTmpAudioState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState('all');

    useEffect(async () => {
        let res;
        if (!translateState && projectData) {
            res = await fetchVoiceOverLanguage(projectData?.file.language.language);
            if (!res.success) {
                setSupported(false);
                return;
            }
        } else {
            res = await fetchVoiceOverLanguage(languageText);
            if (res.success === false) {
                setSupported(false);
                return;
            }
        }
        setSupported(true);
        const result = res.data.sort((a, b) => {
            if (a.provider_id > b.provider_id) return 1;
            if (a.provider_id < b.provider_id) return -1;
            return 0;
        });
        setLanguageList(result);
    }, [translateState, languageText, projectData]);

    const handleClose = () => {
        setVoiceOverModal(false);
    };

    const playVoice = async (selected) => {
        setTmpLanguageId(selected.id);
        setLoading(true);
        setTmpAudioState(true);
        if (tmpAudio !== null && tmpLanguageId === selected.id) {
            if (!tmpAudio.paused) {
                setTmpAudioState(false);
                tmpAudio.pause();
                setLoading(false);
                return;
            }
            tmpAudio.play();
        } else {
            const url = await getVoiceOverPreview(
                selected.provider,
                selected.provider_language_code,
                selected.provider_id,
            );
            const tmp = new Audio(url);
            tmp.onended = function () {
                setTmpAudioState(false);
            };
            setTmpAudioState(true);
            tmp.play();
            setTmpAudio(tmp);
        }
        setLoading(false);
    };

    const applyVoice = async (selected) => {
        if (loading) return;
        let reference_id = id;
        if (translateState) {
            const translateHeader = await fetchTranslationHeader(id, currentLanguage);
            reference_id = translateHeader.id;
        }

        try {
            setLoading(true)
            const voiceOverReq = await createVoiceOver(
                user.id,
                selected.provider,
                selected.provider_language_code,
                selected.provider_id,
                reference_id,
                translateState,
            );
            setLoading(false)

            if (voiceOverReq.success) {
                handleClose();

                actions.setVoiceOverRequest(true);
                actions.setVoiceOverLoaded(false);
                actions.setVoiceOverLoading(true);
                actions.setVoiceOver(null);
                setPlaying(false);
                if (player) {
                    player.pause();
                    player.currentTime = 0;
                }
                const redirectId =
                    projectData?.breadcrumbs_data && projectData?.breadcrumbs_data.length >= 1
                        ? projectData?.breadcrumbs_data[0].id
                        : process.env.REACT_APP_BACKEND_URL.replace('/api', '/projects');

                window.location.href =
                    projectData?.breadcrumbs_data && projectData?.breadcrumbs_data.length >= 1
                        ? process.env.REACT_APP_BACKEND_URL.replace('/api', '/folders') + '?id=' + redirectId
                        : redirectId;

                const timer = setInterval(async () => {
                    try {
                        const voiceOverData = await getVoiceOver(reference_id, translateState);
                        if (voiceOverData.success) {
                            if (voiceOverData.data.status === 'valid') {
                                clearInterval(timer);
                                actions.setVoiceOver(voiceOverData.data);
                                if (
                                    !voiceOverData.data.originalMusiclWaveformUrl ||
                                    voiceOverData.data.originalMusiclWaveformUrl.length <= 0
                                ) {
                                    actions.setOriginalMusicWaveform([0]);
                                } else {
                                    await fetch(voiceOverData.data.originalMusiclWaveformUrl)
                                        .then((response) => response.arrayBuffer())
                                        .then((buffer) => WaveformData.create(buffer))
                                        .then((data) => {
                                            const buffer = new Int8Array(data._data.buffer);
                                            actions.setOriginalMusicWaveform(buffer);
                                        });
                                }
                                if (
                                    !voiceOverData.data.originalAudioWaveformUrl ||
                                    voiceOverData.data.originalAudioWaveformUrl.length <= 0
                                ) {
                                    actions.setOriginalVoiceWaveform([0]);
                                } else {
                                    await fetch(voiceOverData.data.originalAudioWaveformUrl)
                                        .then((response) => response.arrayBuffer())
                                        .then((buffer) => WaveformData.create(buffer))
                                        .then((data) => {
                                            const buffer = new Int8Array(data._data.buffer);
                                            actions.setOriginalVoiceWaveform(buffer);
                                        });
                                }
                                if (
                                    !voiceOverData.data.voiceOverWaveformUrl ||
                                    voiceOverData.data.voiceOverWaveformUrl.length <= 0
                                ) {
                                    actions.setVoiceOverWaveform([0]);
                                } else {
                                    await fetch(voiceOverData.data.voiceOverWaveformUrl)
                                        .then((response) => response.arrayBuffer())
                                        .then((buffer) => WaveformData.create(buffer))
                                        .then((data) => {
                                            const buffer = new Int8Array(data._data.buffer);
                                            actions.setVoiceOverWaveform(buffer);
                                        });
                                }
                                actions.setVoiceOverRequest(false);
                            }
                        }
                    } catch (e) {}
                }, 10000);
            } else {
                toast.error('Failed to create the original voiceover. Please try again later or choose a different voice.', { id: 'VOICEOVER_ERROR' });
            }
        } catch(e) {
            setLoading(false)
            toast.error('Failed to create the original voiceover. Please try again later or choose a different voice.', { id: 'VOICEOVER_ERROR' });
        }
    };

    const RenderVoice = () => {
        let filteredLanguageList = languageList.sort((a, b) => {
            if (a.provider_id === 'original' && b.provider_id !== 'original') {
                return -1;
            }
            if (a.provider_id !== 'original' && b.provider_id === 'original') {
                return 1;
            }

            return 0;
        });

        if (tab === 'male') {
            filteredLanguageList = languageList.filter((l) => {
                return l.gender.toLowerCase() === 'male' || l.provider_id.toLowerCase() === 'original';
            });
        } else if (tab === 'female') {
            filteredLanguageList = languageList.filter((l) => {
                return l.gender.toLowerCase() === 'female' || l.provider_id.toLowerCase() === 'original';
            });
        }

        const hasOriginal = filteredLanguageList.findIndex((x) => x.provider_id == 'original') >= 0
        const originalLength = filteredLanguageList.filter((x) => x.provider_id == 'original').length

        return filteredLanguageList.map((l, i) => {
            return (
                <>
                    <tr>
                        <td>
                            {l.provider_id} - {l.language}
                        </td>
                        <td>
                            <div className="flex flex-wrap gap-2 items-center justify-end">
                                {l.have_preview ? (
                                    <button
                                        disabled={loading}
                                        className="btn bg-white font-bold rounded-3px"
                                        onClick={() => {
                                            playVoice(l);
                                        }}
                                        style={{
                                            width: '50px',
                                        }}
                                    >
                                        {loading && tmpLanguageId === l.id ? (
                                            <Oval stroke="#17BDB7" height="1rem" width="20" />
                                        ) : tmpAudio == null || tmpAudio.paused || tmpAudioState === false ? (
                                            <Play className="w-4 h-4" stroke="#17BDB7" fill="#17BDB7" />
                                        ) : tmpLanguageId === l.id ? (
                                            <Pause className="w-4 h-4" stroke="#17BDB7" fill="#17BDB7" />
                                        ) : (
                                            <Play className="w-4 h-4" stroke="#17BDB7" fill="#17BDB7" />
                                        )}
                                    </button>
                                ) : ''}
                                <button
                                    disabled={loading}
                                    onClick={() => applyVoice(l)}
                                    className="btn btn-lexigo-green text-white font-bold rounded-3px"
                                >
                                    Apply this voice
                                </button>
                            </div>
                        </td>
                    </tr>
                    {hasOriginal && i == (originalLength - 1) && (
                        <tr>
                            <td colspan="2" style={{
                                color: '#17bdb7',
                                position: 'relative'
                            }}>
                                Others
                                <span style={{
                                    display: 'inline-block',
                                    content: '""',
                                    height: '2px',
                                    background: '#17bdb7',
                                    position: 'absolute',
                                    width: 'calc(100% - 100px)',
                                    top: '50%',
                                    translate: 'transformY(-50%)',
                                    marginLeft: '10px'
                                }}></span>
                            </td>
                        </tr>
                    )}
                </>
            );
        });
    };

    return (
        <>
            <Modal open={voiceOverModal} onClose={handleClose}>
                <div
                    id="modal-voice-over"
                    className="modal show"
                    style={{
                        marginTop: '0px',
                        marginLeft: '0px',
                        paddingLeft: '0px',
                        zIndex: 10000,
                    }}
                    tabIndex="-1"
                    aria-hidden="true"
                    data-backdrop="static"
                    role="dialog"
                >
                    <div className="modal-dialog">
                        <div className="modal-content modal-transparent h-full border-0">
                            <div className="modal-header flex items-center justify-between">
                                <p className="modal-title font-bold">Select Voice</p>
                                <a data-dismiss="modal" href="javascript:;" onClick={() => handleClose()}>
                                    <X className="w-5 h-5" />
                                </a>
                            </div>
                            <div className="modal-body h-full">
                                {supported ? (
                                    <>
                                        <div className="mb-4 flex justify-center">
                                            <div
                                                className="nav nav-tabs nav-tabs-invited justify-center rounded-3px box"
                                                role="tablist"
                                                id="invited-user-tab"
                                            >
                                                <a
                                                    onClick={() => {
                                                        setTab('all');
                                                    }}
                                                    id="all-tab"
                                                    className={`voice-tab-btn py-2 px-4 rounded-md text-center ${
                                                        tab === 'all' ? 'active' : ''
                                                    }`}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    role="tab"
                                                >
                                                    All
                                                </a>
                                                <a
                                                    onClick={() => {
                                                        setTab('male');
                                                    }}
                                                    id="male-tab"
                                                    className={`voice-tab-btn py-2 px-4 rounded-md text-center ${
                                                        tab === 'male' ? 'active' : ''
                                                    }`}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    role="tab"
                                                >
                                                    Male
                                                </a>
                                                <a
                                                    onClick={() => {
                                                        setTab('female');
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    id="female-tab"
                                                    className={`voice-tab-btn py-2 px-4 rounded-md text-center ${
                                                        tab === 'female' ? 'active' : ''
                                                    }`}
                                                    role="tab"
                                                >
                                                    Female
                                                </a>
                                            </div>
                                        </div>

                                        <div style={{ maxHeight: 'calc(100% - 200px)', overflow: 'auto' }}>
                                            <table className="table" style={{ padding: '1.75rem 2rem' }}>
                                                <tbody>
                                                    <RenderVoice />
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                ) : (
                                    <Typography id="modal-modal-title">Language not supported</Typography>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default VoiceOverModal;
