import Button from '../components/Button';
import Settings from '../components/Settings';
import { LocalMediaContext } from '../providers/LocalMediaContext';
import { ModalContext } from '../providers/ModalContext';
import { UserSettingsContext } from '../providers/UserSettingsContext';
import { useContext, useEffect, useRef, useState } from 'react';
import MicrophoneStream from "microphone-stream";
import toast from 'react-hot-toast';
import Ably from 'ably';
import { useChannel } from 'ably/react'
import {
    TranscribeStreamingClient,
    StartStreamTranscriptionCommand,
  } from "@aws-sdk/client-transcribe-streaming";
import getUserMedia from 'get-user-media-promise';
import { IvsClient, ListStreamSessionsCommand } from "@aws-sdk/client-ivs"; // ES Modules import
import axios from 'axios';

let microphoneStream = undefined;
const language = "en-US";
const SAMPLE_RATE = 44100;

const useBroadcastSDK = () => {
    const { streamKey, ingestEndpoint, languagesList, arn } = useContext(UserSettingsContext);
    const { toggleModal, setModalProps, setModalContent } = useContext(ModalContext);

    const [broadcastClientMounted, setBroadcastClientMounted] = useState(false);
    const [isLive, setIsLive] = useState(false);
    const [isSupported, setIsSupported] = useState(true);
    const [streamPending, setStreamPending] = useState(false);
    const [connectionState, setConnectionState] = useState();
    const [clientErrors, setClientErrors] = useState([]);
    const IVSBroadcastClientRef = useRef(undefined);
    const broadcastClientRef = useRef(undefined);
    const transcribeClientRef = useRef(undefined);
    const broadcastClientEventsRef = useRef(undefined);
    const startTimeRef = useRef(undefined);
    const sdkVersionRef = useRef(undefined);
    const { localAudioDeviceId } = useContext(LocalMediaContext);

    const { publish } = useChannel('scribe');

    const importBroadcastSDK = async () => {
        const sdk = (await import('amazon-ivs-web-broadcast')).default;
        broadcastClientEventsRef.current = sdk.BroadcastClientEvents;
        IVSBroadcastClientRef.current = sdk;
        return sdk;
    };

    const createBroadcastClient = async ({ config: streamConfig }) => {
        const IVSBroadcastClient = IVSBroadcastClientRef.current
            ? IVSBroadcastClientRef.current
            : await importBroadcastSDK();

        const client = IVSBroadcastClient.create({
            streamConfig,
        });

        broadcastClientRef.current = client;
        sdkVersionRef.current = IVSBroadcastClient.__version;
        setIsSupported(IVSBroadcastClient.isSupported());
        attachBroadcastClientListeners(client);

        // Hack to get fix react state update issue
        setBroadcastClientMounted(new Date());

        return client;
    };

    const destroyBroadcastClient = (client) => {
        detachBroadcastClientListeners(client);
        client.delete();
        setBroadcastClientMounted(false);
    };

    const attachBroadcastClientListeners = (client) => {
        client.on(broadcastClientEventsRef.current.CONNECTION_STATE_CHANGE, handleConnectionStateChange);
        client.on(broadcastClientEventsRef.current.ACTIVE_STATE_CHANGE, handleActiveStateChange);
        client.on(broadcastClientEventsRef.current.ERROR, handleClientError);
    };

    const detachBroadcastClientListeners = (client) => {
        client.off(broadcastClientEventsRef.current.CONNECTION_STATE_CHANGE, handleConnectionStateChange);
        client.off(broadcastClientEventsRef.current.ACTIVE_STATE_CHANGE, handleActiveStateChange);
        client.off(broadcastClientEventsRef.current.ERROR, handleClientError);
    };

    const restartBroadcastClient = async ({ config, ingestEndpoint }) => {
        if (microphoneStream) {
          microphoneStream.stop();
          microphoneStream.destroy();
          microphoneStream = undefined;
        }
        if (transcribeClientRef.current) {
          transcribeClientRef.current.destroy();
          transcribeClientRef.current = undefined;
        }

        if (isLive) stopStream(broadcastClientRef.current);
        destroyBroadcastClient(broadcastClientRef.current);

        const newClient = await createBroadcastClient({
            config,
            ingestEndpoint,
        });

        return newClient;
    };

    const handleActiveStateChange = (active) => {
        console.log({active})
        setIsLive(active);
    };

    const handleConnectionStateChange = async (state) => {
        setConnectionState(state);

        if (state == "disconnected" || state == "failed") {
          toast.error('Connection Error occured. Broadcast Disconnected ', {
              id: 'STREAM_STATUS',
          });

          await stopStreamForce(broadcastClientRef.current)
        }
    };

    useEffect(() => {
      console.log({connectionState, clientErrors})
    }, [connectionState, clientErrors]);

    const handleClientError = async (clientError) => {
        console.log({clientError})
        toast.error('Error occured. ' + clientError.message, {
            id: 'STREAM_STATUS',
        });

        await stopStreamForce(broadcastClientRef.current)

        setClientErrors((prevState) => [...prevState, clientError]);
    };

    const stopStream = async (client) => {
        try {
            if (microphoneStream) {
              microphoneStream.stop();
              microphoneStream.destroy();
              microphoneStream = undefined;
            }
            if (transcribeClientRef.current) {
              transcribeClientRef.current.destroy();
              transcribeClientRef.current = undefined;
            }

            setStreamPending(true);
            toast.loading('Stopping stream...', { id: 'STREAM_STATUS' });
            await client.stopBroadcast();
            startTimeRef.current = undefined;
            toast.success('Stopped stream', { id: 'STREAM_STATUS' });
        } catch (err) {
            toast.error('Failed to stop stream', {
                id: 'STREAM_STATUS',
            });
        } finally {
            setStreamPending(false);
            toast.remove('STREAM_TIMEOUT');
        }
    };

    const stopStreamForce = async (client) => {
        try {
            if (microphoneStream) {
              microphoneStream.stop();
              microphoneStream.destroy();
              microphoneStream = undefined;
            }
            if (transcribeClientRef.current) {
              transcribeClientRef.current.destroy();
              transcribeClientRef.current = undefined;
            }

            setStreamPending(true);
            await client.stopBroadcast();
            startTimeRef.current = undefined;
        } catch (err) {
        } finally {
            setStreamPending(false);
        }
    };

    const encodePCMChunk = (chunk) => {
        const input = MicrophoneStream.toRaw(chunk);
        let offset = 0;
        const buffer = new ArrayBuffer(input.length * 2);
        const view = new DataView(buffer);
        for (let i = 0; i < input.length; i++, offset += 2) {
          let s = Math.max(-1, Math.min(1, input[i]));
          view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
        }
        return Buffer.from(buffer);
    };

    const getAudioStream = async function* () {
        for await (const chunk of microphoneStream) {
            if (chunk.length <= SAMPLE_RATE) {
                yield {
                    AudioEvent: {
                        AudioChunk: encodePCMChunk(chunk),
                        time: new Date
                    },
                };
            }
        }
    };

    const createTranscribeClient = () => {
      transcribeClientRef.current = new TranscribeStreamingClient({
        region: process.env.REACT_APP_AWS_REGION_TRANSCRIBE,
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        }
      });
    };

    const createMicrophoneStream = async () => {
      microphoneStream = new MicrophoneStream();
      microphoneStream.setStream(
        await window.navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        }),
      );
    };

    const startStreaming = async (language) => {
      try {
        const command = new StartStreamTranscriptionCommand({
          LanguageCode: languagesList,
          MediaEncoding: "pcm",
          MediaSampleRateHertz: SAMPLE_RATE,
          AudioStream: getAudioStream(),
          EnablePartialResultsStabilization: true
        });

        let isDoneGet = false
        let streamId = null
        const data = await transcribeClientRef.current.send(command);
        for await (const event of data.TranscriptResultStream) {
          if (!isDoneGet) {
            try {
                // statements
                const clientSID = new IvsClient({
                    region: process.env.REACT_APP_AWS_REGION_STREAM,
                    credentials: {
                        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
                        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
                    }
                });
                const inputSID = {
                  channelArn: arn
                };
                const commandSID = new ListStreamSessionsCommand(inputSID);
                const response = await clientSID.send(commandSID);
                
                if (response && response.streamSessions && response.streamSessions.length > 0) {
                  streamId = response.streamSessions[0].streamId
                }
            } catch(e) {
                console.log({e})
            }
            
            isDoneGet = true
          }

          const results = event.TranscriptEvent.Transcript.Results;
          if (results.length) {
            const newTranscript = results[0].Alternatives[0].Transcript;

            if (newTranscript) {
              publish(streamKey, {
                text: newTranscript 
              , startTime: results[0].StartTime
              , endTime: results[0].EndTime
              , isPartial: results[0]?.IsPartial
              , arn
              });

              if (streamId) {
                try {
                    axios.post(`${process.env.REACT_APP_BACKEND_URL}/live-caption/save-caption`, {
                      arn,
                      streamId,
                      startTime: results[0].StartTime,
                      endTime: results[0].EndTime,
                      text: newTranscript
                    })
                    .then((res) => {
                        // console.log(res);
                    });
                } catch (e) {
                    console.log(e);
                }
              }
            }
          }
        }
      } catch(e) {
        // statements
        toast.error('Error occured. Stream stopped', {
            id: 'STREAM_STATUS',
        });

        await stopStream(broadcastClientRef.current)
      }
    };

    const startStream = async ({ client, streamKey, ingestEndpoint }) => {
        var streamTimeout;

        if (microphoneStream || transcribeClientRef.current) {
            if (microphoneStream) {
              microphoneStream.stop();
              microphoneStream.destroy();
              microphoneStream = undefined;
            }
            if (transcribeClientRef.current) {
              transcribeClientRef.current.destroy();
              transcribeClientRef.current = undefined;
            }
        }
        createTranscribeClient();
        createMicrophoneStream();

        try {
            toast.loading(
                (t) => {
                    return (
                        <span>
                            <span className="pr-4">Starting stream...</span>
                            <Button
                                type="toast"
                                onClick={() => {
                                    toast.dismiss(t.id);
                                    stopStream(client);
                                }}
                            >
                                Stop
                            </Button>
                        </span>
                    );
                },
                { id: 'STREAM_STATUS' },
            );
            setStreamPending(true);

            streamTimeout = setTimeout(() => {
                toast(
                    (t) => {
                        return (
                            <span className="text-black/50">
                                It's taking longer than usual to start the stream. If you are on a VPN, check if port
                                4443 is unblocked and try again.
                            </span>
                        );
                    },
                    { id: 'STREAM_TIMEOUT', duration: Infinity, icon: '⚠️' },
                );
            }, 5000);
            const broadCast = await client.startBroadcast(streamKey, ingestEndpoint).then((result) => {
              clearTimeout(streamTimeout);
              startTimeRef.current = new Date();
              toast.success('Started stream.', { id: 'STREAM_STATUS' });
            }).catch((error) => {
              console.error('Something drastically failed while broadcasting!', error);
            });
        } catch (err) {
            clearTimeout(streamTimeout);
            console.error({err});

            if (err.code === 18000) {
                // Stream key invalid error
                // See: https://aws.github.io/amazon-ivs-web-broadcast/docs/v1.3.1/sdk-reference/namespaces/Errors?_highlight=streamkeyinvalidcharerror#stream_key_invalid_char_error
                toast(
                    (t) => {
                        return (
                            <div className="flex items-center">
                                <span className="pr-4 grow">
                                    <strong>Invalid stream key.</strong> Enter a valid stream key to continue.
                                </span>
                                <span className="shrink-0">
                                    <Button
                                        type="toast"
                                        onClick={() => {
                                            toast.dismiss(t.id);
                                            setModalProps({
                                                type: 'full',
                                            });
                                            setModalContent(<Settings />);
                                            toggleModal();
                                        }}
                                    >
                                        Open settings
                                    </Button>
                                </span>
                            </div>
                        );
                    },
                    {
                        id: 'STREAM_STATUS',
                        position: 'bottom-center',
                        duration: Infinity,
                        style: {
                            minWidth: '24rem',
                            width: '100%',
                        },
                    },
                );
            } else {
                toast.error('Failed to start stream', {
                    id: 'STREAM_STATUS',
                });
            }
        } finally {
            toast.remove('STREAM_TIMEOUT');
            setStreamPending(false);

            await startStreaming(language);
        }
    };

    const toggleStream = async () => {
        if (isLive) {
            await stopStream(broadcastClientRef.current);
        } else {
            await startStream({
                client: broadcastClientRef.current,
                streamKey,
                ingestEndpoint,
            });
        }
    };

    return {
        IVSBroadcastClientRef,
        sdkVersionRef,
        broadcastClientMounted,
        broadcastClientRef,
        connectionState,
        isLive,
        isSupported,
        streamPending,
        broadcastStartTimeRef: startTimeRef,
        broadcastErrors: clientErrors,
        toggleStream,
        stopStream,
        startStream,
        createBroadcastClient,
        destroyBroadcastClient,
        restartBroadcastClient,
    };
};

export default useBroadcastSDK;
