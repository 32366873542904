import { MicrophoneIcon } from '@heroicons/react/24/solid';
import DownloadIcon from '../../components/Icon/DownloadIcon.js';
import toast from 'react-hot-toast';
import Button from '../Button';
import axios from 'axios';
import { useState, useEffect, useContext, useRef, useCallback } from 'react';

export function DownloadCaptionButton({ isLive, downloadUrl }) {
  const [captionExist, setCaptionExist] = useState(null);

  const handleClick = async () => {
    const toastId = toast.loading('Generating subtitles file...');
    
    try {
      const response = await axios.get(downloadUrl);

      toast.dismiss(toastId);
      window.open(downloadUrl, '_blank');
    } catch(e) {
      // statements
      toast.dismiss(toastId);
      toast.error('No caption file found.', { id: 'DOWNLOAD_CAPTION_ERROR' });
    }

  };

  useEffect(async ()=>{
    if (!captionExist && downloadUrl) {
      try {
        const response = await axios.get(downloadUrl);

        setCaptionExist('haha')
      } catch(e) {
        setCaptionExist('hoho')
      }
    }
  },[downloadUrl])

  useEffect(async ()=>{
    if (isLive) {
      setCaptionExist('haha')
    }
  },[isLive])

  return (
    <>
      { (captionExist == 'haha' || isLive) && (
        <button
            type="button"
            className="btn btn-lexigo-green text-white font-bold rounded-3px btn-save-hide-mobile"
            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
            onClick={handleClick}
        >
          <DownloadIcon
              className="text-white h-6 w-6"
          />
        </button>
      )}
    </>
  );
}
