import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import toast from 'react-hot-toast';
import CryptoJS  from 'crypto-js';
import canAutoPlay from 'can-autoplay';
import Ably from 'ably';
import { useChannel } from 'ably/react'
import axios from 'axios';
import PlayerControls from './PlayerControls';
import PlayerAutoPlayBlocked from './PlayerAutoPlayBlocked';
import './custom.css';
import { IvsClient, ListStreamSessionsCommand } from "@aws-sdk/client-ivs"; // ES Modules import

const CAPTIONS_MAX_DISPLAY_TIME = 5;
const CAPTIONS_UPDATE_INTERVAL = 100;
const CAPTIONS_MAX_ERRORS = 20;
const IOS_LINE_POSITION = -4;
const LINE_POSITION = -3;

export default function WatchBroadcastApp({location}) {
  const searchParams = new URLSearchParams(location.search);
  const deviceDetect = require('react-device-detect');

  const { IVSPlayer } = window;
  const { isPlayerSupported } = IVSPlayer;
  const [overlays, setOverlays] = useState([]);
  const [placeHolderStatus, setPlaceHolderStatus] = useState('loading');
  const [showSettings, setShowSettings] = useState(false);
  const [showTranslate, setShowTranslate] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [transcriptionsQueue, setTranscriptionsQueue] = useState([]);
  const [transcriptionErrors, setTranscriptionErrors] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isAudioBlocked, setIsAudioBlocked] = useState(false);
  const [isVideoBlocked, setIsVideoBlocked] = useState(false);
  const [showCaptions, setShowCaptions] = useState(true);
  const [currentRunningTime, setCurrentRunningTime] = useState(0);
  const [showCaptionsButtonState, setShowCaptionsButtonState] = useState(true);

  const player = useRef(null);
  const videoEl = useRef(null);
  const trackEl = useRef(null);
  const playerWrapper = useRef(null);
  const captionsInterval = useRef(null);
  const webSocket = useRef(null);

  const [ingetsEndpoint, setIngetsEndpoint] = useState(null);
  const [streamKey, setStreamKey] = useState(null);
  const [playbackUrl, setPlaybackUrl] = useState(null);
  const [arn, setArn] = useState(null);
  const [streamId, setStreamId] = useState(null);

  useEffect(() => {
    const val = searchParams.get('val');
    
    let key = process.env.REACT_APP_KEY//'NzHhmhYt9N1LMMHhws5pTt1JtW4StuWk9jfQ/plX+GI=';
    let encryptStr = CryptoJS.enc.Base64.parse(val);
    let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
    encryptData = JSON.parse(encryptData);
    let iv = CryptoJS.enc.Base64.parse(encryptData.iv);
    let decrypted = CryptoJS.AES.decrypt(encryptData.value,  CryptoJS.enc.Base64.parse(key), {
        iv : iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    decrypted = JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted));

    setIngetsEndpoint(decrypted.ingest_endpoint);
    setStreamKey(decrypted.stream_key);
    setPlaybackUrl(decrypted.playback_url);
    setArn(decrypted.arn);
  }, [searchParams, ingetsEndpoint, streamKey, playbackUrl]);
  
  const onStateChange = useCallback(async () => {
    const playerState = player.current.getState();

    setIsPlaying(playerState === IVSPlayer.PlayerState.PLAYING);

    if (playerState === IVSPlayer.PlayerState.PLAYING) {
      const response = await axios.get(playbackUrl);
      parseHLSManifest(response.data)

      setPlaceHolderStatus(null);
      if (trackEl && trackEl.current) {
        trackEl.current.track.mode = 'showing';
      }

      // try {
      //     // statements
      //     const clientSID = new IvsClient({
      //         region: process.env.REACT_APP_AWS_REGION_STREAM,
      //         credentials: {
      //             accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      //             secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
      //         }
      //     });
      //     const inputSID = {
      //       channelArn: arn
      //     };
      //     const commandSID = new ListStreamSessionsCommand(inputSID);
      //     const response = await clientSID.send(commandSID);
          
      //     if (response && response.streamSessions && response.streamSessions.length > 0) {
      //       setStreamId(response.streamSessions[0].streamId)
      //     }
      // } catch(e) {
      //     console.log({e})
      // }
    } else if (playerState === IVSPlayer.PlayerState.ENDED) {
      setPlaceHolderStatus('This live stream has ended');
    }
  }, [IVSPlayer.PlayerState, playbackUrl]);

  const parseHLSManifest = (manifest) => {
    const lines = manifest.split('\n');
    lines.forEach(line => {
      if (line.startsWith('#EXT-X-SESSION-DATA:DATA-ID="STREAM-TIME"')) {
        const seconds = parseFloat((line.split('=')[2]).replace(/[^\d.]+/g, ''));
        setCurrentRunningTime(seconds);
      }
    });
  };

  const onError = (err) => {
    setPlaceHolderStatus('This live stream is currently offline');
  };

  const onRebuffering = () => {
    player.current.setRebufferToLive(true);
  };

  const onTextMetadataCue = useCallback((textMetadataCue) => {
    const metadata = JSON.parse(textMetadataCue.text);

    if (metadata.type === 'overlay') {
      handleOverlays(metadata);
    }
  }, []);

  // Updates the array containing the overlays to be shown according to keywords in the transcription.
  const handleOverlays = (data) => {
    const { keyword, imgUrl, url } = data;
    const imgId = new Date().getTime();

    // A maximum of <MAX_OVERLAYS> overlays are shown at the same time.
    setOverlays((oldOverlays) => {
      let newOverlays = [...oldOverlays];

      // Removes the overlay if already exists in the array.
      // Otherwise, if the maximum is reached, removes the first overlay.
      if (newOverlays.find((overlay) => overlay.keyword === keyword)) {
        newOverlays = newOverlays.filter((overlay) => overlay.keyword !== keyword);
      } else if (newOverlays.length >= 4) {
        newOverlays.shift();
      }

      newOverlays.push({ id: imgId, keyword, imgUrl, url });
      return newOverlays;
    });

    // Each overlay is removed from the array after a period defined by <TIME_OVERLAYS>.
    setTimeout(() => {
      setOverlays((oldOverlays) => oldOverlays.filter((overlay) => overlay.id !== imgId));
    }, 6000);
  };

  const onFullScreenChange = () => {
    if (document.fullscreenElement) {
      setIsFullscreen(true);
    } else {
      setIsFullscreen(false);
    }
  };

  const AddEventListeners = useCallback(() => {
    const video = playerWrapper.current.getElementsByTagName('video')[0];

    player.current.addEventListener(IVSPlayer.PlayerState.READY, onStateChange);
    player.current.addEventListener(IVSPlayer.PlayerState.PLAYING, onStateChange);
    player.current.addEventListener(IVSPlayer.PlayerState.BUFFERING, onStateChange);
    player.current.addEventListener(IVSPlayer.PlayerState.IDLE, onStateChange);
    player.current.addEventListener(IVSPlayer.PlayerState.ENDED, onStateChange);
    player.current.addEventListener(IVSPlayer.PlayerEventType.ERROR, onError);
    player.current.addEventListener(IVSPlayer.PlayerEventType.REBUFFERING, onRebuffering);
    player.current.addEventListener(IVSPlayer.PlayerEventType.TEXT_METADATA_CUE, onTextMetadataCue);

    if (deviceDetect.isMobileSafari) {
      video.addEventListener('webkitendfullscreen', onFullScreenChange);
    } else if (deviceDetect.isSafari) {
      document.addEventListener('webkitfullscreenchange', onFullScreenChange);
    } else {
      document.addEventListener('fullscreenchange', onFullScreenChange);
    }
  }, [IVSPlayer.PlayerState, IVSPlayer.PlayerEventType, deviceDetect.isMobileSafari, deviceDetect.isSafari, onStateChange, onTextMetadataCue]);

  const RemoveEventListeners = useCallback(() => {
    const video = playerWrapper.current.getElementsByTagName('video')[0];

    player.current.removeEventListener(IVSPlayer.PlayerState.READY, onStateChange);
    player.current.removeEventListener(IVSPlayer.PlayerState.PLAYING, onStateChange);
    player.current.removeEventListener(IVSPlayer.PlayerState.BUFFERING, onStateChange);
    player.current.removeEventListener(IVSPlayer.PlayerState.IDLE, onStateChange);
    player.current.removeEventListener(IVSPlayer.PlayerState.ENDED, onStateChange);
    player.current.addEventListener(IVSPlayer.PlayerEventType.ERROR, onError);
    player.current.addEventListener(IVSPlayer.PlayerEventType.REBUFFERING, onRebuffering);
    player.current.addEventListener(IVSPlayer.PlayerEventType.TEXT_METADATA_CUE, onTextMetadataCue);

    if (deviceDetect.isMobileSafari) {
      video.removeEventListener('webkitendfullscreen', onFullScreenChange);
    } else if (deviceDetect.isSafari) {
      document.removeEventListener('webkitfullscreenchange', onFullScreenChange);
    } else {
      document.removeEventListener('fullscreenchange', onFullScreenChange);
    }
  }, [
    player,
    IVSPlayer.PlayerState,
    IVSPlayer.PlayerEventType,
    deviceDetect.isMobileSafari,
    deviceDetect.isSafari,
    onStateChange,
    onTextMetadataCue,
  ]);

  /** WEBSOCKET CONNECTION useEffect - START **/

  const onMessage = (data) => {
    setTranscriptionsQueue((oldQueue) => {
      const newQueue = [...oldQueue];
      newQueue.push(data);
      newQueue.sort((a, b) => a.startTime - b.startTime);
      return newQueue;
    });
  };

  const { channel } = useChannel('scribe', streamKey, async (sub) => {
    if (playbackUrl && sub.data && sub.data.text) {
      onMessage(sub.data)
    }
  })

  const removeTranscriptionsFromQueue = (playerPosition, timeCorrection) => {
    const syncPosition = playerPosition - timeCorrection;

    setTranscriptionsQueue((oldQueue) => {
      const newQueue = [...oldQueue];
      let i = 0;

      while (i < newQueue.length && newQueue[i].endTime <= syncPosition) {
        i++;
      }

      newQueue.splice(0, i + 1);

      return newQueue;
    });
  };

  const shiftTranscriptionsQueue = () => {
    setTranscriptionsQueue((oldQueue) => {
      const newQueue = [...oldQueue];
      newQueue.shift();
      return newQueue;
    });
  };

  const createNewCueAndAddToTrack = (startTime, endTime, text) => {
    // sanity checks
    if (!trackEl?.current?.track?.cues) return;

    // get current track and cues
    const track = trackEl.current.track;
    const cues = track.cues;

    // create new cue
    /* eslint-disable no-undef */
    const newCue = new VTTCue(startTime, endTime, text);
    /* eslint-disable no-undef */

    // format new cue accordingly
    // if (config.ENABLE_TRANSLATE === 'true' && config.RIGHT_ALIGNED_LANGUAGES.includes(currentLanguage)) {
    //   if (deviceDetect.isMacOs && deviceDetect.isSafari) {
    //     newCue.align = 'center';
    //     newCue.positionAlign = 'line-right';
    //     newCue.position = 40;
    //   } else {
    //     newCue.align = 'right';
    //     newCue.position = 80;
    //   }
    // } else {
      newCue.align = 'left';
      newCue.position = 20;
    // }
    newCue.size = 60;

    // increase distance between player bottom and captions for translations (because they can span in more than 2 rows)
    // if (currentLanguage !== 'en') {
    //   newCue.line = deviceDetect.isIOS ? IOS_LINE_POSITION - 1 : LINE_POSITION - 1;
    // } else {
      newCue.line = deviceDetect.isIOS ? IOS_LINE_POSITION : LINE_POSITION;
    // }

    // remove old cues
    while (cues.length > 0) {
      track.removeCue(cues[0]);
    }

    // add new cue
    track.addCue(newCue);
    
    // try {
    //     axios.post(`${process.env.REACT_APP_BACKEND_URL}/live-caption/save-caption`, {
    //       arn,
    //       streamId,
    //       startTime,
    //       endTime,
    //       text
    //     })
    //     .then((res) => {
    //         console.log(res);
    //     });
    // } catch (e) {
    //     console.log(e);
    // }
  };

  const showCaption = (startTime, endTime, text) => {
    setShowCaptions(true);
    createNewCueAndAddToTrack(startTime, endTime, text);
    shiftTranscriptionsQueue();
  };

  const updateCaptions = () => {
    // sanity checks
    if (!transcriptionsQueue.length || !showCaptionsButtonState) return;

    // get new caption
    const newCaption = transcriptionsQueue[0];

    if (deviceDetect.isIOS && (deviceDetect.deviceType === 'mobile' || deviceDetect.isChrome)) {
      showIOSCaption(newCaption, player, showCaption, shiftTranscriptionsQueue);
      return;
    }

    // calculate start and end times
    const playerPosition = player.current.getPosition();
    // NOTE: getStartOffset is an unsupported player API. As such, it may be changed or deprecated without notice
    const playerStartOffset = player.current.getStartOffset();

    let playerLiveLatency;
    if (deviceDetect.isOpera) {
      playerLiveLatency = player.current.isLiveLowLatency() ? 2 : 4;
    } else {
      playerLiveLatency = player.current.getLiveLatency();
    }

    const timeCorrection = -(playerStartOffset + playerLiveLatency);
    const endTimeCorrection = timeCorrection + CAPTIONS_MAX_DISPLAY_TIME;

    const startTime = newCaption.startTime + timeCorrection;
    const endTime = newCaption.endTime + endTimeCorrection;

    // check times and display caption
    if (startTime <= playerPosition && endTime >= playerPosition) {
      showCaption(startTime, endTime, newCaption.text);
      setTranscriptionErrors(0);
    } else if (endTime <= playerPosition) {
      removeTranscriptionsFromQueue(playerPosition, endTimeCorrection);
      setTranscriptionErrors((errors) => errors + 1);
    }
  };

  useEffect(() => {
    captionsInterval.current = setInterval(() => updateCaptions(), CAPTIONS_UPDATE_INTERVAL);
    return () => clearInterval(captionsInterval.current);
  });

  /** UPDATE CAPTIONS useEffect - END **/

  /** CAPTIONS ERRORS useEffect - START **/

  useEffect(() => {
    if (transcriptionErrors > CAPTIONS_MAX_ERRORS) {
      window.location.reload();
    }
  }, [transcriptionErrors]);

  /** CAPTIONS ERRORS useEffect - END **/

  useEffect(() => {
    if (!isPlayerSupported) {
      console.warn('The current browser does not support the Amazon IVS player.');
      return;
    }

    player.current = IVSPlayer.create();
    player.current.attachHTMLVideoElement(videoEl.current);

    AddEventListeners();

    player.current.load(playbackUrl);

    // Ask if the browser allows autoplay with sound
    canAutoPlay.video({ muted: false, inline: true, timeout: 1000 }).then(({ result, error }) => {
      if (result) {
        player.current.play();
      } else {
        console.warn(error);
        canAutoplayMuted();
      }
    });

    // Ask for autoplay without sound
    const canAutoplayMuted = () =>
      canAutoPlay.video({ muted: true, inline: true, timeout: 1000 }).then(({ result }) => {
        if (result) {
          setIsAudioBlocked(true);
          player.current.setMuted(true);
          player.current.play();
        } else {
          setIsVideoBlocked(true);
        }
      });

    return () => {
      RemoveEventListeners();
    };
  }, [IVSPlayer, isPlayerSupported, playbackUrl, AddEventListeners, RemoveEventListeners]);

  useEffect(() => {
    if (trackEl.current) {
      if (showCaptions) {
        trackEl.current.track.mode = 'showing';
      } else {
        trackEl.current.track.mode = 'hidden';
      }
    }
  }, [showCaptions]);

  const clearTranscriptionsQueue = () => {
    setTranscriptionsQueue([]);
  };

  const toggleFullscreen = () => {
    const elem = document;
    const video = playerWrapper.current.getElementsByTagName('video')[0];
    if (isFullscreen) {
      if (elem.exitFullscreen) {
        elem.exitFullscreen();
      } else if (elem.webkitExitFullscreen) {
        /* Safari */
        elem.webkitExitFullscreen();
      } else if (elem.msExitFullscreen) {
        /* IE11 */
        elem.msExitFullscreen();
      } else if (video.webkitExitFullScreen) {
        /* IOS */
        video.webkitExitFullScreen();
      }
    } else {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) {
        /* Safari */
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        /* IE11 */
        video.msRequestFullscreen();
      } else if (video.webkitEnterFullscreen) {
        /* IOS */
        video.webkitEnterFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  if (!isPlayerSupported) {
    return null;
  }

  const startPlayback = () => {
    player.current.play();
    setIsVideoBlocked(false);
  };

  return (
    <>
      <div className="stream-wrapper" ref={playerWrapper}>
        <div className="aspect-16x9">
          <div className="player">
            <video ref={videoEl} className="video-el" playsInline preload="metadata" crossOrigin="anonymous">
              {!placeHolderStatus && <track ref={trackEl} kind="captions" default />}
            </video>

            <div className="player-ui">
              {player.current && !isVideoBlocked && (
                <PlayerControls
                  placeHolderStatus={placeHolderStatus}
                  player={player.current}
                  isFullscreen={isFullscreen}
                  toggleFullscreen={toggleFullscreen}
                  startsMuted={isAudioBlocked}
                />
              )}

              {isVideoBlocked && <PlayerAutoPlayBlocked startPlayback={startPlayback} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
